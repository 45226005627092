.app__location {
  display: flex;
  flex-direction: column;
  align-items: center;

  .location-container {
    margin: 4rem 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    border-radius: 5px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);

    .image-container {
      position: relative;
      width: 100%; // Адаптация на все экраны
      height: 500px;

      overflow: hidden;

      iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .location-details {
      padding: 10px;
      padding-top: 10px;
      text-align: center;
      margin-top: 1rem;

      h2 {
        font-size: 1.25rem;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        margin-bottom: 2rem;
      }
    }
  }

  // Адаптация на маленькие экраны (мобильные)
  @media screen and (max-width: 480px) {
    .location-container {
      .image-container {
        height: 300px;
      }
    }
  }
}
