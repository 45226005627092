.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;

  .app__profiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 3rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media screen and (min-width: 1600px) and (max-width: 1900px) {
      grid-template-columns: repeat(2, minmax(250px, 1fr));
    }

    h2 {
      font-size: 19px;
      text-align: center;
    }

    .app__profile-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem;
      padding: 1rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

      .image-container {
        width: 100%;
        max-height: 170px;
        overflow: hidden;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bold-text {
        font-size: 18px;
        margin-top: 1.5rem;
        text-align: center;
      }

      .description-container {
        padding: 10px;
        border-radius: 5px;
        margin-top: 0.5rem;
        text-align: center;

        .p-text {
          font-size: 16px;
          color: var(--black-color);
          margin: 0;
        }
      }
    }
  }
}
