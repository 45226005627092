.app_navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;

  .app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      max-width: 140px;
    }

    @media screen and (min-width: 2000px) {
      img {
        max-width: 140px;
      }
    }

    @media screen and (max-width: 768px) {
      img {
        max-width: 120px; // Меньший размер логотипа на маленьких экранах
      }
    }
  }

  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    li {
      margin: 0 1rem;
      cursor: pointer;
      flex-direction: column;

      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
        margin-bottom: 5px;
      }

      a {
        color: var(--black-color);
        text-decoration: none;
        flex-direction: column;
        text-transform: uppercase;
        font-weight: 500;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }

        &:hover div {
          background: var(--secondary-color);
        }
      }

      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }

  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);

    svg {
      width: 70%;
      height: 70%;
      color: var(--white-color);
    }

    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
      padding: 1rem;
      width: 80%;
      height: 100vh;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      background: url("../../assets/bgWhite.jpg");
      background-color: var(--white-color);
      background-size: cover;
      background-repeat: repeat;
      box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        li {
          margin: 1rem;
          a {
            color: var(--black-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }

      @media screen and (min-width: 900px) {
        display: none;
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  .app__navbar-lang {
    display: flex;
    align-items: center;

    button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--white-color);
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;

      svg {
        width: 15px;
        height: 15px;
        color: var(--gray-color);
      }

      &:hover {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        svg {
          color: var(--white-color);
        }
      }

      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
        margin: 0.5rem 0;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }
}

.gpt3__navbar-menu {
  margin-right: 1rem;

  display: none;
  position: relative;
}

.change-language {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: underline;

  .language-icon {
    margin-right: 0.5rem;

    svg {
      width: 28px;
      height: 28px;
      color: var(--secondary-color);

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.gpt3__navbar-menu svg {
  cursor: pointer;
}

.gpt3__navbar-menu_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: var(--primary-color);
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1.5rem;
  min-width: 210px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background: url("../../assets/bgWhite.jpg");
  background-color: var(--white-color);
  background-size: cover;
  background-repeat: repeat;

  li {
    list-style: none;
  }

  button {
    padding: 8px 8px;
    font-size: 0.8rem;
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;

    &:hover {
      color: var(--secondary-color);
    }
  }

  @media screen and (max-width: 2400px) {
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 920px) {
    margin-top: 1.3rem;
  }
}

@media screen and (max-width: 2400px) {
  .gpt3__navbar-links_container {
    display: flex;
  }

  .gpt3__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .gpt3__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__navbar {
    padding: 2rem;
  }

  .gpt3__navbar-menu_container {
    top: 20px;
  }
}

.navbar_links-container {
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    li {
      margin: 0.5rem;
      a {
        color: var(--black-color);
        text-decoration: none;
        font-size: 0.9rem;
        text-transform: uppercase;

        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }
}

.navbar-menu {
  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
}

.navbar-menu_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  text-align: center;
  background: var(--primary-color);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1.5rem;
  min-width: 210px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background: url("../../assets/bgWhite.jpg");
  background-color: var(--white-color);
  background-size: cover;
  background-repeat: repeat;

  @media screen and (max-width: 900px) {
    margin-top: 1.5rem;
  }
}

.navbar-menu_container p {
  margin: 1rem 0;
}

@media screen and (max-width: 900px) {
  .gpt3__navbar-links_container {
    display: flex;
  }

  .navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .gpt3__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__navbar {
    padding: 2rem;
  }

  .navbar-menu_container {
    top: 20px;
  }
}
