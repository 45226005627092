.projects-container {
  margin: 4rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .project-item {
    width: 100%;
    min-height: 320px;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;

    transition: all 0.3s ease-in-out;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .image-container {
      position: relative;
      width: 100%;
      max-width: 400px;
      height: auto;
      border-radius: 5px;
      margin-right: 2.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .image-slideshow {
      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }

    .image-navigation {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 10px;
      z-index: 2;
      pointer-events: none;

      .prev,
      .next {
        background-color: rgba(0, 0, 0, 0.5);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
        pointer-events: auto;
        transition: background-color 0.3s;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .prev {
        margin-right: 2rem;
      }

      .next {
        margin-left: 2rem;
      }
    }

    .project-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 768px) {
        align-items: flex-start;
      }

      h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 1rem;
        color: var(--black-color);
        text-align: center;

        @media screen and (min-width: 768px) {
          text-align: left;
        }
      }

      p {
        font-size: 16px;
        margin-bottom: 8px;
        text-align: center;

        @media screen and (min-width: 768px) {
          text-align: left;
        }
      }

      .building-characteristics {
        h3 {
          margin-top: 2rem;
          margin-bottom: 0.5rem;
          font-size: 20px;
          font-weight: bold;
        }

        .p-text {
          margin: 0;
        }
      }

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }

  .navigation-btns {
    flex-direction: row;
    margin-top: 1rem;

    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--secondary-color);
      border: solid 1px var(--secondary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
        color: var(--white-color);
      }

      &:hover {
        background-color: var(--white-color);

        svg {
          color: var(--secondary-color);
        }
      }

      @media screen and (min-width: 2000px) {
        width: 100px;
        height: 100px;

        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
  }
}
